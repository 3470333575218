'use client'
import React from 'react'
import { useState, useEffect } from 'react'
import { currencies } from '@constants'
import { getCookie } from 'cookies-next';


const CurrencySymbol = () => {
    const [currencySymbol, setCurrencySymbol] = useState('฿');
    useEffect(() => {
        const savedCurrency = getCookie('currency');

        const matchedCurrency = currencies.find(currency => currency.value === savedCurrency);

        if (matchedCurrency) {
            setCurrencySymbol(matchedCurrency.symbol);
        }
    });

    return (
        <span>{currencySymbol}</span>
    )
}

export default CurrencySymbol