'use client'
import React, { useState, useEffect, useRef } from 'react'
// import { useSession } from 'next-auth/react'
import Image from 'next/image'
import LinkComponent from './LinkComponent'
import { AiOutlineLayout, AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiArea, BiSolidArea } from "react-icons/bi";
import { FaBed, FaBath } from "react-icons/fa"
import { FaMountain } from "react-icons/fa";
import { MdBeachAccess, MdPlaylistAddCheckCircle } from "react-icons/md";
import { FaWater } from "react-icons/fa";
import { IoMdVideocam } from 'react-icons/io'
import tailwindConfig from '@tailwind.config'
import CurrencySymbol from '@components/CurrencySymbol'

// import { sendLike } from '@services/sendLike'
// import CurrencySymbol from '@components/CurrencySymbol'
// import { categoryTypes } from '@constants'


const PropertyCard = ({ property, imageWidth = 400, imageHeight = 300, newLimit, isLast, cardType = undefined, dictionary, lang }) => {
    // const [showLikeBtn, setShowLikeBtn] = useState(false)
    // const [isLiked, setIsLiked] = useState(property.isLiked)
    // const { data: session } = useSession()
    const cardRef = useRef();

    // const handleLike = async () => {
    //     const isLike = await sendLike(property.id, session?.user?.email)
    //     setIsLiked((prevLike) => !prevLike)
    //     property.isLiked = !property.isLiked
    // };

    useEffect(() => {
        if (!cardRef?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (isLast && entry.isIntersecting) {
                newLimit();
                // nextPage()
                observer.unobserve(entry.target);
            }
        });

        observer.observe(cardRef.current);
    }, [isLast]);

    // useEffect(() => {
    //     if (session && session.user !== null) {
    //         setShowLikeBtn(true)
    //     }
    // }, [])

    return (
        <div className='relative w-full m-1 rounded-lg shadow-md hover:shadow-sm hover:shadow-maincolor'
            ref={cardRef}
        >
            <LinkComponent
                className='w-full'
                href={'/properties/' + property.slug}
            >
                <div className='relative w-full aspect-[4/3] rounded-t-md overflow-hidden'>
                    {/* {(showLikeBtn == true && isLiked == false) && (
                        <div title="Left-click on this icon to save this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-secondary rounded-md p-1'>
                            <AiOutlineHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.secondary}
                            />
                        </div>
                    )} */}
                    {/* {(showLikeBtn == true && isLiked == true) && (
                        <div title="Left-click to unlike this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-third rounded-md p-1'>
                            <AiFillHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.third}
                            />
                        </div>
                    )} */}
                    <Image
                        // placeholder="blur"
                        // blurDataURL={property.blur}
                        // className='object-fit'
                        className='object-cover'
                        src={property.cover}
                        // width={imageWidth}
                        // height={imageHeight}
                        fill={true}
                        alt={property.slug}
                    />
                        {(property.videoUrl || property.isSeaView || property.isBeachfront || property.isMountainView || property.foreignQuota) ? (
                            <div className='absolute w-full bottom-1 left-1 flex flex-row flex-wrap'>
                                {/* {(property.foreignQuota && property.category == 'Condo' && property.saleType == 'sale') ? (
                                    <div className={`rounded-md p-1 text-third border-third border-[1px] text-sm mr-1 flex flex-row items-center`}><MdPlaylistAddCheckCircle /><span className='ml-1'>Foreign Quota</span></div>
                                ) : null} */}
                                {property.videoUrl ? (
                                    <div className={`rounded-md p-1 text-light bg-red-800 text-sm mr-1 flex flex-row items-center`}><IoMdVideocam /><span className='ml-1'>Video</span></div>
                                ) : null}
                                {property.isSeaView ? (
                                    <div className={`rounded-md p-1 bg-maincolor text-light border-light border-[1px] text-sm mr-1 flex flex-row items-center`}><FaWater /><span className='ml-1'>Sea View</span></div>
                                ) : null}
                                {property.isBeachfront ? (
                                    <div className={`rounded-md p-1 bg-third text-light border-third border-[1px] text-sm mr-1 flex flex-row items-center`}><MdBeachAccess /><span className='ml-1'>Beachfront</span></div>
                                ) : null}
                                {/* {property.isMountainView ? (
                                    <div className={`rounded-md p-1 text-secondary border-secondary border-[1px] text-sm mr-1 flex flex-row items-center`}><FaMountain /><span className='ml-1'>Mountain View</span></div>
                                ) : null} */}
                            </div>
                        ) : null}
                    {property.publicTransport ? (
                        <div className='absolute flex flex-row flex-wrap bottom-0 left-0'>
                            {property.publicTransport.map((transport, index) => (
                                <div key={index} style={{ backgroundColor: transport.colorCode }} className={`rounded-sm px-1 text-light text-sm ml-1 mb-1`}>{transport.type.toUpperCase()} {transport.stationName}</div>
                            ))}
                        </div>
                    ) : null}
                </div>
                {cardType == 'new' && (
                    <div className={`absolute left-2 top-2 ${property.listedAt == 'Listed Today' ? 'bg-third' : 'bg-maincolor'}  text-light p-1 text-md rounded-md z-10`}><h3>{property.listedAt}</h3></div>
                )}
                {cardType == 'promotion' && (
                    <div className={`absolute left-2 top-2 bg-third text-light p-1 text-md rounded-md z-10`}><h3>{property.promotionTitle}</h3></div>
                )}
                <div className='p-2'>
                    {/* <div className='text-maincolor text-xl font-semibold rounded-r-md z-10'><h3>{property.category} for {property.saleType !== 'salerent' ? property.saleType : 'Sale and Rent'}</h3></div> */}
                    <div className='text-maincolor text-xl font-semibold rounded-r-md z-10 h-[60px]'><h3 className='line-clamp-2'>{property.name}</h3></div>
                    <div className='flex sm:flex-row flex-col w-full '>
                        {property.salePrice && (
                            <div className='text-xl font-semibold text-third w-1/2'><CurrencySymbol />{property.salePrice}</div>
                        )}
                        {property.rentPrice && (
                            <div className='text-xl font-semibold text-third w-1/2'><CurrencySymbol />{property.rentPrice}/{dictionary.month}</div>
                        )}
                    </div>
                    <div className='w-full border-b-[1px] border-forth pb-1 mb-1'>
                        <p className='text-secondary text-xl'>{property.districts[0]?.subdistrictName?.[lang] ? property.districts[0]?.subdistrictName?.[lang] : property.districts[0]?.subdistrict}</p>
                    </div>
                    {(property.category == 'Condo' || property.category == 'House' || property.category == 'Poolvilla' || property.category == 'Commercial') &&
                        <div className='flex flex-row w-full'>
                            <div className='flex flex-row items-center w-1/2'>
                                <FaBed
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                {(property.bedrooms && (property.bedrooms > 0 || typeof property.bedrooms == 'string')) ? (
                                    <span className='ml-2 text-xl text-maincolor'>{property.bedrooms} {typeof property.bedrooms !== 'string' ? dictionary.beds : ''}</span>
                                ):null}
                            </div>
                            {(property.bathrooms && property.bathrooms > 0) ? (
                                <div className='flex flex-row items-center w-1/2'>
                                    <FaBath
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                    <span className='ml-2 text-xl text-maincolor'>{property.bathrooms} {dictionary.baths}</span>
                                </div>
                            ):null}
                        </div>

                    }
                    <div className='flex flex-row w-full justify-between min-h-7'>
                        {(property.livingArea !== undefined && property.livingArea > 0) ? (
                            <div className='flex flex-row items-center w-1/2'>
                                <BiArea
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='ml-2 text-xl text-maincolor'>{property.livingArea} {dictionary.sqm}</span>
                            </div>
                        ): null}
                        {(property.landArea !== undefined && property.landArea > 0) && (
                            <div className='flex flex-row items-center w-1/2'>
                                <BiSolidArea
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                                <span className='ml-2 text-xl text-maincolor'>{property.landArea} {dictionary.sqm}</span>
                            </div>
                        )}
                    </div>
                </div>
            </LinkComponent >
        </div>
    )
}

export default PropertyCard